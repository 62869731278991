@forward 'vars';
@use 'sass:list';
@use '../../../core/src/styles/vars' as psv;
@use 'vars' as virtual-tour;

.psv-container {
    --psv-virtual-tour-plugin-loaded: true;
}

.psv-virtual-tour-tooltip {
    h3 {
        margin: 0;
        padding: 0;
        line-height: normal;

        &:not(:last-child) {
            margin-bottom: #{list.nth(psv.$tooltip-padding, 1)};
        }
    }

    img {
        display: block;
        width: psv.$tooltip-max-width;
        margin: 0 #{- list.nth(psv.$tooltip-padding, 2)};

        &:first-child {
            border-radius: psv.$tooltip-radius psv.$tooltip-radius 0 0;
        }

        &:last-child {
            border-radius: 0 0 psv.$tooltip-radius psv.$tooltip-radius;
        }

        &:not(:last-child) {
            margin-bottom: #{list.nth(psv.$tooltip-padding, 1)};
        }
    }

    p {
        margin: 0;
        padding: 0;
        line-height: normal;
    }
}

.psv-virtual-tour-link {
    cursor: pointer;
    transform-origin: center;
}

.psv-virtual-tour-arrows {
    position: absolute;
    overflow: visible !important;
    z-index: psv.$hud-zindex + 1;
    bottom: 0;
    left: 0;
    filter: virtual-tour.$link-shadow;
    pointer-events: none;
    transition: margin ease-in-out 0.3s;

    .psv-virtual-tour-link {
        transition: opacity linear .2s;

        &:hover {
            opacity: 1 !important;
        }
    }
}

.psv-virtual-tour-arrow {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    color: virtual-tour.$link-button-color;
    border-radius: 50%;

    &:hover {
        animation: psv-virtual-tour-link-button-in 1s ease-out forwards;
    }
}

.psv-virtual-tour-loader {
    display: block;
    position: relative;
    width: 40px;
    height: 30px;
    margin: 0 40px;

    div {
        display: inline-block;
        position: absolute;
        left: 10%;
        width: 20%;
        background: #fff;
        animation: psv-virtual-tour-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

        &:nth-child(1) {
            left: 10%;
            animation-delay: -0.24s;
        }

        &:nth-child(2) {
            left: 40%;
            animation-delay: -0.12s;
        }

        &:nth-child(3) {
            left: 70%;
            animation-delay: 0;
        }
    }
}

@keyframes psv-virtual-tour-loader {
    0% {
        top: 10%;
        height: 80%;
    }

    50%,
    100% {
        top: 30%;
        height: 40%;
    }
}

@keyframes psv-virtual-tour-link-button-in {
    0% {
        box-shadow: 0 0 0 0 rgba(virtual-tour.$link-button-ring, 0);
    }

    20% {
        box-shadow: 0 0 0 5px rgba(virtual-tour.$link-button-ring, 1);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(virtual-tour.$link-button-ring, 0);
    }
}
